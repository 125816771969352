<template>
  <div>
    <div class="table-page-title">
      <div class="fl">
        <el-form :model="searchForm" class="search-form" :inline="true">
          <el-form-item label="" prop="dispose">
            <el-select clearable class="small-input" v-model="searchForm.dispose" placeholder="状态">
              <el-option
                  v-for="item in disposeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="type">
            <el-select clearable class="small-input" style="width: 200px" v-model="searchForm.type" placeholder="异常类型">
              <el-option
                  v-for="item in typeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="departmentId">
            <el-select clearable class="small-input" style="width: 200px" v-model="searchForm.departmentId" placeholder="所在单位">
              <el-option
                  v-for="item in departmentList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="fl search-form-btn">
        <el-button class="fl" size="small" type="primary" @click="search()">搜 索</el-button>
        <el-button class="fl" size="small" @click="reset()">重 置</el-button>
      </div>
    </div>
    <div class="table-container">
      <el-table
          class="table"
          height="100%"
          v-loading="tableLoading"
          :data="tableData">
        <el-table-column
            label="上报时间"
            prop="submitTime"
            width="200"
            align="center"
            header-align="center"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="异常类型"
            prop="typeName"
            align="center"
            header-align="center"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="设备名称"
            prop="name"
            align="center"
            header-align="center"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="货道编号"
            prop="mainWayNo"
            align="center"
            header-align="center"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="库存"
            prop="inventory"
            align="center"
            header-align="center"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="所在单位"
            prop="department"
            align="center"
            header-align="center"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="所在位置"
            prop="position"
            align="center"
            header-align="center"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="运营联系人"
            prop="contacts"
            align="center"
            header-align="center"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="是否已处理"
            prop="disposeShow"
            align="center"
            header-align="center"
        >
          <template slot-scope="scope">
            <el-switch style="margin-left: 10px" :disabled="scope.row.disposeShow" @change="changeStatus(scope.row)"
                       v-model="scope.row.disposeShow">
            </el-switch>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
        class="top_10 right right_10"
        :current-page="pageNum"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>
  </div>
</template>

<script>
import tableUse from "@/mixins/tableUse";

export default {
  name: "DeviceAbnormal",
  mixins: [tableUse],
  data() {
    return {
      searchForm: {
        dispose: '',
        type: '',
        departmentId: ''
      },
      tableData: [
      ],
      disposeList:[
        {
          value:0,
          label:'待处理'
        },
        {
          value:1,
          label:'已处理'
        }
      ],
      typeList:[
        {
          value:0,
          label:'APK版本低'
        },
        {
          value:1,
          label:'空货道'
        },
        {
          value:2,
          label:'门未关上'
        },
        {
          value:3,
          label:'设备未联网'
        },
        {
          value:4,
          label:'订单已支付，但门未打开'
        },
        {
          value:5,
          label:'副货道库存低'
        },
        {
          value:6,
          label:'副货道不出货'
        },
        {
          value:7,
          label:'支付了租金但未退押金的订单'
        },
        {
          value:8,
          label:'逾期的订单'
        },
        {
          value:9,
          label:'已支付但门打不开的订单'
        },
      ],
      departmentList:[]
    }
  },
  methods: {
    reset() {
      this.searchForm = {
        dispose: '',
        type: '',
        departmentId: ''
      }
      this.search();
    },
    getDepartmentList(){
      this.$axios.get("/sys-api/site-api/getDepartment",{},(res) => {
        if(res.code === '100'){
          this.departmentList = res.data;
        }
      })
    },
    changeStatus(row){
      this.$axios.postForm("/sys-api/abnormal-api/deviceAbnormalDispose",{id:row.id},(res) => {
        if(res.code === '100'){
          this.$message.success("异常处理成功");
        }else {
          row.disposeShow = !row.disposeShow;
        }
      })
    },
    getTableData(){
      this.tableLoading = true;
      let formData = {
        pageNum:this.pageNum,
        pageSize: this.pageSize
      }
      if(this.searchForm.dispose || this.searchForm.dispose === 0) formData.dispose = this.searchForm.dispose;
      if(this.searchForm.type || this.searchForm.type === 0) formData.type = this.searchForm.type;
      if(this.searchForm.departmentId) formData.departmentId = this.searchForm.departmentId;
      this.$axios.post("/sys-api/abnormal-api/deviceAbnormalPage",formData,(res) => {
        this.tableLoading = false;
        if(res.code === '100'){
          this.total = res.data.total;
          res.data.list.forEach((item) => {
            item.typeName = "";
            item.disposeShow = item.dispose === 1;
            for(let i = 0 ; i < this.typeList.length ; i++){
              if(this.typeList[i].value === item.type){
                item.typeName = this.typeList[i].label;
                break;
              }
            }
          })
          this.tableData = res.data.list;
        }
      })
    }
  },
  mounted() {
    this.getDepartmentList();
    this.getTableData();
  }
}
</script>

<style scoped lang="scss">
</style>
